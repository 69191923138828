import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { SET_LOCK_SCREEN } from 'store/settings/actions'
import { useTranslation } from 'react-i18next'
import io from 'socket.io-client'

// Reactstrap
import { Modal, DropdownMenu, DropdownItem, Button } from 'react-bootstrap'

// Import menuDropdown
import LanguageDropdown from "../topbarDropdown/LanguageDropdown";

import logoIcon from "../../assets/images/logo.png";

import { POST, GET } from 'helpers/api'
import { clearTemporaryStorage, formatMoney } from 'helpers/helpers'

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate();
	const dispatch = useDispatch()

	const socketRef = useRef()

	const [modalLogout, setModalLogout] = useState(false)
	const [balance, setBalance] = useState(0)
	const [notifications, setNotifications] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function lockScreen() {
		dispatch(SET_LOCK_SCREEN())
	}

	function tToggle() {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}

	async function logout() {
		if (process.env.NODE_ENV === 'development') {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
			return
		}
		const response = await POST('/auth/logout')
		if (response) {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
		}
	}

	async function getBalance() {
		const response = await GET('/services/web/api/pos-balance')
		setBalance(response)
	}

	async function getNotifications() {
		const response = await GET('/services/web/api/unread-notifications')
		setNotifications(response)
	}

	const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleNotificationClick = (notification) => {
        setSelectedNotification(notification);
    };

    const handleCloseModal = () => {
        setSelectedNotification(null);
    };

	
	
	let locale = "ru"
	if (localStorage.getItem("I18N_LANGUAGE") === 'ru') locale = "ru"
	else if (localStorage.getItem("I18N_LANGUAGE") === 'uz-Latn-UZ') {locale = "lat"}
	else if (localStorage.getItem("I18N_LANGUAGE") === 'uz-Cyrl-UZ') {locale = "cyr"}
	

	function socketConnection() {
		const baseURL = "https://cabinet.sdpos.io/online-users";
		socketRef.current = io.connect(baseURL, {
			transports: ['websocket'],
			query: {
				"login": JSON.parse(localStorage.getItem('authUser'))?.username,
				"Authorization": localStorage.getItem('access_token')
			}
		});

		socketRef.current.on('connect', function () {
			console.log('Connected', socketRef);
			console.log('Connected', socketRef.connected);
		});

		socketRef.current.on('disconnect', function () {
			console.log('Disconnected');
		});


		socketRef.current.on('online-users', (data) => {
			console.log(data);
		})
	}

	useEffect(() => {
		socketConnection()
		getBalance()
		getNotifications()

		return () => {
			socketRef?.current?.disconnect()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="phone-info d-none">
						<div>{t('PHONE')}: +998 00 000 00 00</div>
						<div>{t('PHONE')}: +998 00 000 00 00</div>
					</div>
					<div className="d-flex">
						<div className="navbar-brand-box">
							<Link to="/" className="logo logo-dark">
								<span className="logo-sm">
									<img src={logoIcon} alt="" height="22" />
								</span>
								<span className="logo-lg">
									<img src={logoIcon} alt="" height="20" />
								</span>
							</Link>
						</div>

						<button
							type="button"
							onClick={() => {
								tToggle();
							}}
							className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
							id="vertical-menu-btn"
						>
							<i className="uil-bars fz-20" />
						</button>
					</div>

					<div className="d-flex">

						<LanguageDropdown />

						<button type="button"
							onClick={() => handleDropdownToggle()}
							className="btn header-item noti-icon waves-effect"
							data-toggle="fullscreen">
							<i className="uil-bell" style={{fontSize: '24px'}}>
							{notifications.length > 0 && (
								<span
								style={{
									top: '-5px',
									right: '-5px',
									backgroundColor: 'orange',
									color: 'white',
									borderRadius: '50%',
									padding: '2px 6px',
									fontSize: '12px',
								}}
								>
								{notifications.length}
								</span>
							)}
							</i>
						</button>
						{showDropdown && (
							<div style={{ position: 'absolute', backgroundColor: 'aliceblue', border: '1px solid #ddd', top: '50px' }}>
								{notifications.length > 0 ? (
									notifications.map((notification) => (
										<div
											key={notification.id}
											onClick={() => handleNotificationClick(notification)}
											style={{ padding: '10px', cursor: 'pointer', borderBottom: '1px solid #ddd', minWidth: '180px' }}
										>
											{JSON.parse(notification.message)[locale].length > 20 ? `${JSON.parse(notification.message)[locale].substring(0, 20)}...` : JSON.parse(notification.message)[locale]}
										</div>
									))
								) : (
									<div style={{ padding: '0px' }}></div>
								)}
							</div>
						)}

						{selectedNotification && (
							<div style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', maxWidth: '600px', minWidth: '300px', position: 'relative' }}>
								<button
									onClick={handleCloseModal}
									style={{ position: 'absolute', top: '0', right: '0', background: 'none', border: 'none', fontSize: '24px', cursor: 'pointer', color: 'gray', padding: '0 10px' }}
									aria-label="Close"
								>
									&times;
								</button>
								<h4 style={{marginRight: '10px'}}>{JSON.parse(selectedNotification.message)[locale]}</h4>
							</div>
						</div>
						)}

						<button type="button"
							onClick={() => toggleFullscreen()}
							className="btn header-item noti-icon waves-effect"
							data-toggle="fullscreen">
							<i className="uil-minus-path"></i>
						</button>
						<button type="button"
							onClick={() => lockScreen()}
							className="btn header-item noti-icon waves-effect"
							data-toggle="fullscreen">
							<i className="uil-lock"></i>
						</button>
						<div className="d-lg-inline-block ms-1 dropdown" onClick={() => setModalLogout(true)}>
							<button type="button" className="btn header-item noti-icon waves-effect">
								<div className="d-flex">
									<div className="me-2">
										<div className="d-flex justify-content-between">
											<span>{t('LOGIN')}: </span>
											<span>{JSON.parse(localStorage.getItem('authUser'))?.username}</span>
										</div>
										<div>	{t('BALANCE')}: {formatMoney(balance, 0)}</div>
									</div>
									<div className="vertical-center">
										<i className="uil-signin"></i>
									</div>
								</div>
							</button>
						</div>

					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header
